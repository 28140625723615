.react-calendar__month-view__days__day--weekend
  + .react-calendar__month-view__days__day--weekend:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ) {
  color: #235c48;
}

.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--weekend)
  + .react-calendar__month-view__days__day--weekend:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ) {
  color: #235c48;
}

@media print {
  body {
    overflow-y: visible;
  }
}
